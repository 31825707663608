import {
  GET_EMAIL_TEMPLATE_SUCCESS,
  GET_EMAIL_TEMPLATE_ERROR,
  CREATE_EMAIL_TEMPLATE_REQUEST,
  CREATE_EMAIL_TEMPLATE_SUCCESS,
  CREATE_EMAIL_TEMPLATE_ERROR,
  UPDATE_EMAIL_TEMPLATE_REQUEST,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_ERROR,
  DELETE_EMAIL_TEMPLATE_REQUEST,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE_ERROR,
  CLEAR_EMAIL_TEMPLATE_FLAG,
  GET_EMAIL_TEMPLATE_REQUEST,
  GET_ALL_EMAIL_TEMPLATES_ERROR,
  GET_ALL_EMAIL_TEMPLATES_SUCCESS,
  GET_ALL_EMAIL_TEMPLATES_REQUEST,
  GET_EMAIL_VARIABLE_REQUEST,
  GET_EMAIL_VARIABLE_SUCCESS,
  GET_EMAIL_VARIABLE_ERROR
} from "../type/EmailTemplateType";

const initialState = {
  allEmailTemplateData: [],
  getAllEmailTemplateSuccess: false,
  getAllEmailTemplateError: false,

  emailTemplateData: [],
  getEmailTemplateSuccess: false,
  getEmailTemplateError: false,

  createEmailTemplateSuccess: false,
  createEmailTemplateError: false,

  updateEmailTemplateSuccess: false,
  updateEmailTemplateError: false,

  deleteEmailTemplateSuccess: false,
  deleteEmailTemplateError: false,

  emailVariableData: [],
  getEmailVariableSuccess: false,
  getEmailVariableError: false
};

export default function emailTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        getAllEmailTemplateSuccess: false,
        getAllEmailTemplateError: false
      };
    case GET_ALL_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        getAllEmailTemplateSuccess: true,
        getAllEmailTemplateError: false,
        allEmailTemplateData: action.payload.data.emailTemplates
      };
    case GET_ALL_EMAIL_TEMPLATES_ERROR:
      return {
        ...state,
        getAllEmailTemplateSuccess: false,
        getAllEmailTemplateError: true
      };
    case GET_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        getEmailTemplateSuccess: false,
        getEmailTemplateError: false
      };
    case GET_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        getEmailTemplateSuccess: true,
        getEmailTemplateError: false,
        emailTemplateData: action.payload.data.emailTemplate
      };
    case GET_EMAIL_TEMPLATE_ERROR:
      return {
        ...state,
        getEmailTemplateSuccess: false,
        getEmailTemplateError: true
      };
    case CREATE_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        createEmailTemplateSuccess: false,
        createEmailTemplateError: false
      };
    case CREATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        createEmailTemplateSuccess: true,
        createEmailTemplateError: false
      };
    case CREATE_EMAIL_TEMPLATE_ERROR:
      return {
        ...state,
        createEmailTemplateSuccess: false,
        createEmailTemplateError: true
      };
    case UPDATE_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        updateEmailTemplateSuccess: false,
        updateEmailTemplateError: false
      };
    case UPDATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        updateEmailTemplateSuccess: true,
        updateEmailTemplateError: false,
        allEmailTemplateData: action.payload.data.emailTemplates
      };
    case UPDATE_EMAIL_TEMPLATE_ERROR:
      return {
        ...state,
        updateEmailTemplateSuccess: false,
        updateEmailTemplateError: true
      };
    case DELETE_EMAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        deleteEmailTemplateSuccess: false,
        deleteEmailTemplateError: false
      };
    case DELETE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        deleteEmailTemplateSuccess: true,
        deleteEmailTemplateError: false,
        allEmailTemplateData: state.allEmailTemplateData.filter(
          item => item.uuid !== action.payload.data.DeleteEmailTemplate.uuid
        )
      };
    case DELETE_EMAIL_TEMPLATE_ERROR:
      return {
        ...state,
        deleteEmailTemplateSuccess: false,
        deleteEmailTemplateError: true
      };
    case GET_EMAIL_VARIABLE_REQUEST:
      return {
        getEmailVariableSuccess: false,
        getEmailVariableError: false
      };
    case GET_EMAIL_VARIABLE_SUCCESS:
      return {
        emailVariableData: action.payload.data,
        getEmailVariableSuccess: true,
        getEmailVariableError: false
      };
    case GET_EMAIL_VARIABLE_ERROR:
      return {
        getEmailVariableSuccess: false,
        getEmailVariableError: true
      };
    case CLEAR_EMAIL_TEMPLATE_FLAG:
      return {
        ...state,
        getAllEmailTemplateSuccess: false,
        getAllEmailTemplateError: false,
        getEmailTemplateSuccess: false,
        getEmailTemplateError: false,
        createEmailTemplateSuccess: false,
        createEmailTemplateError: false,
        updateEmailTemplateSuccess: false,
        updateEmailTemplateError: false,
        deleteEmailTemplateSuccess: false,
        deleteEmailTemplateError: false,
        getEmailVariableSuccess: false,
        getEmailVariableError: false
      };
    default:
      return {
        ...state
      };
  }
}
