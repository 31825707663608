import {
    GET_DASHBOARD_REQUEST,
    GET_DASHBOARD_SUCCESS,
    GET_DASHBOARD_ERROR,
    CLEAR_DASHBOARD_FLAG
  } from "../type/DashboardType";
  
  const initialState = {
    getDashboardSuccess: false,
    getDashboardError: false,
    dashboardData: []
  };
  
  export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
      case GET_DASHBOARD_REQUEST:
        return {
          ...state,
          getDashboardSuccess: false,
          getDashboardError: false
        };
      case GET_DASHBOARD_SUCCESS:
        const {
          payload: { data }
        } = action;
        return {
          ...state,
          getDashboardSuccess: true,
          getDashboardError: false,
          dashboardData: data.dashboard
        };
      case GET_DASHBOARD_ERROR:
        return {
          ...state,
          getDashboardSuccess: false,
          getDashboardError: true
        };
      case CLEAR_DASHBOARD_FLAG:
        return {
          getDashboardSuccess: false,
          getDashboardError: false
        };
      default:
        return {
          ...state
        };
    }
  }
  