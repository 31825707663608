import {
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  CLEAR_LOG_IN_FLAG
} from "../type/LoginType";

const initialState = {
  loginSuccess: false,
  loginError: false
};

export default function logInReducer(state = initialState, action) {
  switch (action.type) {
    case LOG_IN_REQUEST:
      return {
        ...state,
        loginSuccess: false,
        loginError: false
      };
    case LOG_IN_SUCCESS:
      const {
        payload: { data }
      } = action;
      localStorage.setItem("token", data.Login.token);
      localStorage.setItem("name", data.Login.user.name);
      return {
        ...state,
        loginSuccess: true,
        loginError: false
      };
    case LOG_IN_ERROR:
      return {
        ...state,
        loginSuccess: false,
        loginError: true
      };
    case CLEAR_LOG_IN_FLAG:
      return {
        loginSuccess: false,
        loginError: false
      };
    default:
      return {
        ...state
      };
  }
}
