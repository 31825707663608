import { combineReducers } from "redux";
import logInReducer from "./reducers/LoginReducer";
import patientReducer from "./reducers/PatientReducer";
import cmsReducer from "./reducers/CmsReducer";
import questionReducer from "./reducers/QuestionReducer";
import emailConfigurationReducer from "./reducers/EmailConfigurationReducer";
import emailTemplateReducer from "./reducers/EmailTemplateReducer";
import dashboardReducer from "./reducers/DashboardReducer";

export default combineReducers({
  logInReducer,
  patientReducer,
  cmsReducer,
  questionReducer,
  emailConfigurationReducer,
  emailTemplateReducer,
  dashboardReducer
});
