import gql from "graphql-tag";

export const EmailConfigurations = gql`
  query emailConfigurations {
    emailConfigurations {
      uuid
      hostname
      server_ip
      port
      smtp_details
      smtp_password
      encryption_type
      sender_email
      sender_name
      message
    }
  }
`;

export const EmailConfiguration = gql`
  query emailConfiguration($uuid: String) {
    emailConfiguration(uuid: $uuid) {
      uuid
      hostname
      server_ip
      port
      smtp_details
      smtp_password
      encryption_type
      sender_email
      sender_name
      message
    }
  }
`;
