import {
  GET_PATIENT_REQUEST,
  CLEAR_PATIENT_FLAGS,
  GET_PATIENT_ERROR,
  GET_PATIENT_SUCCESS
} from "../type/PatientType";
import { GetUsers } from "../../graphql/query/PatientQuery";

export const getPatientRequest = () => {
  return {
    type: GET_PATIENT_REQUEST
  };
};

export const getPatientSuccess = payload => {
  return {
    type: GET_PATIENT_SUCCESS,
    payload
  };
};

export const getPatientError = payload => {
  return {
    type: GET_PATIENT_ERROR,
    payload
  };
};

export const getPatientData = client => {
  return dispatch => {
    dispatch(getPatientRequest);
    return client
      .query({
        query: GetUsers,
        fetchPolicy: "no-cache"
      })
      .then(result => dispatch(getPatientSuccess(result)))
      .catch(error => dispatch(getPatientError(error)));
  };
};

export const clearPatientFlags = () => {
  return {
    type: CLEAR_PATIENT_FLAGS
  };
};
