import gql from "graphql-tag";

// Create Cms Page
export const CreateCms = gql`
  mutation CreateCms(
    $name: String
    $description: String
    $page_description: String
  ) {
    CreateCms(
      name: $name
      description: $description
      page_description: $page_description
    ) {
      uuid
      name
      description
    }
  }
`;

// Update Cms Page
export const UpdateCms = gql`
mutation UpdateCms($uuid:String!, $name:String, $description:String, $page_description: String) {
    UpdateCms(uuid:$uuid, name: $name, description: $description, page_description:$page_description) {
         uuid
      name
      description
      page_description
    }
  }`

// Delete Cms page
export const DeleteCms = gql`
  mutation DeleteCms($uuid: String!) {
    DeleteCms(uuid: $uuid) {
      uuid
      name
      message
    }
  }
`;
