import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField } from "@material-ui/core";
import ReactQuill from "react-quill";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuBook from "@material-ui/icons/ListAlt";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Button from "components/CustomButtons/Button.js";
import {
  updateCmsPage,
  getCmsPage,
  clearCmsFlag,
  createCmsPage
} from "../../redux/actions/CmsActions";
import Snackbar from "components/Snackbar/SnackbarContent";
import CardIcon from "components/Card/CardIcon";

class ManageCMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      page_description: "",
      uuid: props.location.state && props.location.state.uuid,
      isEdit: props.location.state && props.location.state.isEdit,
      openSnackBar: false,
      snackBarVariant: "error",
      response: ""
    };
  }

  componentDidMount() {
    const { getCmsPage, client } = this.props;
    const { uuid } = this.state;
    uuid && getCmsPage({ uuid }, client);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      getCmsSuccess,
      cmsData,
      clearCmsFlag,
      updateCmsSuccess,
      updateCmsError,
      createCmsSuccess,
      createCmsError
    } = props;

    if (getCmsSuccess) {
      clearCmsFlag();
      return {
        name: cmsData.name,
        description: cmsData.description,
        page_description: cmsData.page_description
      };
    }
    if (updateCmsSuccess) {
      clearCmsFlag();
      props.history.push("/admin/cms-list");
    }
    if (updateCmsError) {
      clearCmsFlag();
      return {
        openSnackBar: true,
        snackBarVariant: "error",
        response: "Something went wrong, try again!"
      };
    }
    if (createCmsSuccess) {
      clearCmsFlag();
      props.history.push("/admin/cms-list");
    }
    if (createCmsError) {
      clearCmsFlag();
      return {
        openSnackBar: true,
        snackBarVariant: "error",
        response: "Something went wrong, try again!"
      };
    }

    return null;
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCQuillChange = value => {
    this.setState({ page_description: value });
  };

  updateCMS = () => {
    const { updateCmsPage, client } = this.props;
    const { uuid, name, description, page_description } = this.state;
    const updatedData = {
      uuid,
      name,
      description,
      page_description
    };
    updateCmsPage(updatedData, client);
  };

  createCMS = () => {
    const { createCmsPage, client } = this.props;
    const { name, description, page_description } = this.state;
    const createData = {
      name,
      description,
      page_description
    };
    createCmsPage(createData, client);
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false
    });
  };

  render() {
    const { classes } = this.props;
    const {
      name,
      description,
      page_description,
      isEdit,
      openSnackBar,
      snackBarVariant,
      response
    } = this.state;
    return (
      <>
        <Snackbar
          open={openSnackBar}
          handleClose={this.handleCloseSnackbar}
          variant={snackBarVariant}
          message={response}
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <CardIcon color="info">
                  <MenuBook />
                </CardIcon>
              </CardHeader>
              <CardBody className={classes.subscriptionCard}>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Page Name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Page Name"
                        type="text"
                        name="name"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={name}
                        onChange={this.handleChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Description
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        label="Description"
                        type="text"
                        name="description"
                        autoComplete=""
                        margin="normal"
                        variant="outlined"
                        value={description}
                        onChange={this.handleChange}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ paddingTop: 10 }}
                      >
                        Content
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <ReactQuill
                        style={{
                          marginTop: "15px",
                          minHeight: "500px",
                          height: "80%",
                          display: "block"
                        }}
                        value={page_description}
                        onChange={this.handleCQuillChange}
                        theme={"snow"}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                {isEdit ? (
                  <Button
                    type="submit"
                    variant="contained"
                    size="sm"
                    color="info"
                    style={{ fontSize: "0.95rem", marginTop: 30 }}
                    onClick={this.updateCMS}
                  >
                    UPDATE
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    size="sm"
                    color="info"
                    style={{ fontSize: "0.95rem", marginTop: 30 }}
                    onClick={this.createCMS}
                  >
                    SAVE
                  </Button>
                )}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
ManageCMS.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    cmsData: state.cmsReducer && state.cmsReducer.cmsData,
    getCmsSuccess: state.cmsReducer && state.cmsReducer.getCmsSuccess,
    updateCmsSuccess: state.cmsReducer && state.cmsReducer.updateCmsSuccess,
    updateCmsError: state.cmsReducer && state.cmsReducer.updateCmsError,
    createCmsSuccess: state.cmsReducer && state.cmsReducer.createCmsSuccess,
    createCmsError: state.cmsReducer && state.cmsReducer.createCmsError
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateCmsPage,
      getCmsPage,
      createCmsPage,
      clearCmsFlag
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(ManageCMS)));
