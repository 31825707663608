import {
  GET_ALL_EMAIL_TEMPLATES_REQUEST,
  GET_ALL_EMAIL_TEMPLATES_SUCCESS,
  GET_ALL_EMAIL_TEMPLATES_ERROR,
  GET_EMAIL_TEMPLATE_REQUEST,
  GET_EMAIL_TEMPLATE_SUCCESS,
  GET_EMAIL_TEMPLATE_ERROR,
  CREATE_EMAIL_TEMPLATE_REQUEST,
  CREATE_EMAIL_TEMPLATE_SUCCESS,
  CREATE_EMAIL_TEMPLATE_ERROR,
  DELETE_EMAIL_TEMPLATE_REQUEST,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE_ERROR,
  UPDATE_EMAIL_TEMPLATE_REQUEST,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_ERROR,
  CLEAR_EMAIL_TEMPLATE_FLAG,
  GET_EMAIL_VARIABLE_REQUEST,
  GET_EMAIL_VARIABLE_SUCCESS,
  GET_EMAIL_VARIABLE_ERROR
} from "../../redux/type/EmailTemplateType";
import {
  EmailTemplates,
  EmailTemplate,
  EmailVariables
} from "../../graphql/query/EmailTemplateQuery";
import {
  CreateEmailTemplate,
  UpdateEmailTemplate,
  DeleteEmailTemplate
} from "../../graphql/mutation/EmailTemplateMutation";

//Get All Email Templates
export const getAllEmailTemplateRequest = () => {
  return {
    type: GET_ALL_EMAIL_TEMPLATES_REQUEST
  };
};

export const getAllEmailTemplateSuccess = payload => {
  return {
    type: GET_ALL_EMAIL_TEMPLATES_SUCCESS,
    payload
  };
};

export const getAllEmailTemplateError = payload => {
  return {
    type: GET_ALL_EMAIL_TEMPLATES_ERROR,
    payload
  };
};

export const getAllEmailTemplate = client => {
  return dispatch => {
    dispatch(getAllEmailTemplateRequest);
    return client
      .query({
        query: EmailTemplates,
        fetchPolicy: "no-cache"
      })
      .then(result => 
         dispatch(getAllEmailTemplateSuccess(result))
      )
      .catch(error => 
         dispatch(getAllEmailTemplateError(error))
      );
  };
};

// Get Email Template
export const getEmailTemplateRequest = payload => {
  return {
    type: GET_EMAIL_TEMPLATE_REQUEST,
    payload
  };
};

export const getEmailTemplateSuccess = payload => {
  return {
    type: GET_EMAIL_TEMPLATE_SUCCESS,
    payload
  };
};

export const getEmailTemplateError = payload => {
  return {
    type: GET_EMAIL_TEMPLATE_ERROR,
    payload
  };
};

export const getEmailTemplate = (values, client) => {
  return dispatch => {
    dispatch(getEmailTemplateRequest);
    return client
      .query({
        variables: values,
        query: EmailTemplate,
        fetchPolicy: "no-cache"
      })
      .then(result => 
         dispatch(getEmailTemplateSuccess(result))
      )
      .catch(error => 
         dispatch(getEmailTemplateError(error))
      );
  };
};

// Create Email Template
export const createEmailTemplateRequest = payload => {
  return {
    type: CREATE_EMAIL_TEMPLATE_REQUEST,
    payload
  };
};

export const createEmailTemplateSuccess = payload => {
  return {
    type: CREATE_EMAIL_TEMPLATE_SUCCESS,
    payload
  };
};

export const createEmailTemplateError = payload => {
  return {
    type: CREATE_EMAIL_TEMPLATE_ERROR,
    payload
  };
};

export const createEmailTemplate = (values, client) => {
  return dispatch => {
    dispatch(createEmailTemplateRequest);
    return client
      .mutate({
        variables: values,
        mutation: CreateEmailTemplate
      })
      .then(result => 
         dispatch(createEmailTemplateSuccess(result))
      )
      .catch(error => 
         dispatch(createEmailTemplate(error))
      );
  };
};

//Update Email Template
export const updateEmailTemplateRequest = payload => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_REQUEST,
    payload
  };
};

export const updateEmailTemplateSuccess = payload => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_SUCCESS,
    payload
  };
};

export const updateEmailTemplateError = payload => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_ERROR,
    payload
  };
};

export const updateEmailTemplate = (values, client) => {
  return dispatch => {
    dispatch(updateEmailTemplateRequest);
    return client
      .mutate({
        variables: values,
        mutation: UpdateEmailTemplate
      })
      .then(result => 
         dispatch(updateEmailTemplateSuccess(result))
      )
      .catch(error => 
         dispatch(updateEmailTemplateError(error))
      );
  };
};

//Delete Email Template
export const deleteEmailTemplateRequest = payload => {
  return {
    type: DELETE_EMAIL_TEMPLATE_REQUEST,
    payload
  };
};

export const deleteEmailTemplateSuccess = payload => {
  return {
    type: DELETE_EMAIL_TEMPLATE_SUCCESS,
    payload
  };
};

export const deleteEmailTemplateError = payload => {
  return {
    type: DELETE_EMAIL_TEMPLATE_ERROR,
    payload
  };
};

export const deleteEmailTemplate = (values, client) => {
  return dispatch => {
    dispatch(deleteEmailTemplateRequest);
    return client
      .mutate({
        variables: values,
        mutation: DeleteEmailTemplate
      })
      .then(result => 
         dispatch(deleteEmailTemplateSuccess(result))
      )
      .catch(error => 
         dispatch(deleteEmailTemplateError(error))
      );
  };
};

// Get Email Template Variables
export const getEmailVariableRequest = () => {
  return {
    type: GET_EMAIL_VARIABLE_REQUEST,
  };
};

export const getEmailVariableSuccess = payload => {
  return {
    type: GET_EMAIL_VARIABLE_SUCCESS,
    payload
  };
};

export const getEmailVariableError = payload => {
  return {
    type: GET_EMAIL_VARIABLE_ERROR,
    payload
  };
};

export const getEmailVariable = (client) => {
  return dispatch => {
    dispatch(getEmailVariableRequest);
    return client
      .query({
        query: EmailVariables
      })
      .then(result => 
         dispatch(getEmailVariableSuccess(result))
      )
      .catch(error => 
         dispatch(getEmailVariableError(error))
      );
  };
};


// clear Flag
export const clearEmailTemplateFlag = () => {
  return {
    type: CLEAR_EMAIL_TEMPLATE_FLAG,
  };
};