import axios from 'axios';

import history from '../routes/history.js';
import { GetBaseApiEndPoint } from './../appConfig';
const BASE_URL = GetBaseApiEndPoint()

let valid_token = `${localStorage.getItem('token')}`;

let reqConfig = {
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${valid_token}`,
	},
	params: {},
};


export async function postRequestWithOutToken(url, data) {
	return axios
		.post(`${url}`, data, reqConfig)
		.then(response => response)
		.catch(error => {
			console.log(error, "error")
			error.response.status === 401 && history.replace('/');
			throw error;
		});
}

export async function putRequestWithOutToken(url, data) {
	return axios
		.put(`${BASE_URL}${url}`, data, reqConfig)
		.then(response => response)
		.catch(error => {
			error.response.status === 401 && history.replace('/');
			throw error;
		});
}

export async function getRequestWithOutToken(url) {
	return axios
		.get(`${BASE_URL}${url}`)
		.then(response => response)
		.catch(error => {
			error.response.status === 401 && history.replace('/');
			throw error;
		});
}
