import {
  GET_ALL_EMAIL_CONFIGURATION_REQUEST,
  GET_ALL_EMAIL_CONFIGURATION_SUCCESS,
  GET_ALL_EMAIL_CONFIGURATION_ERROR,
  GET_EMAIL_CONFIGURATION_REQUEST,
  GET_EMAIL_CONFIGURATION_SUCCESS,
  GET_EMAIL_CONFIGURATION_ERROR,
  CREATE_EMAIL_CONFIGURATION_REQUEST,
  CREATE_EMAIL_CONFIGURATION_SUCCESS,
  CREATE_EMAIL_CONFIGURATION_ERROR,
  UPDATE_EMAIL_CONFIGURATION_REQUEST,
  UPDATE_EMAIL_CONFIGURATION_SUCCESS,
  UPDATE_EMAIL_CONFIGURATION_ERROR,
  DELETE_EMAIL_CONFIGURATION_REQUEST,
  DELETE_EMAIL_CONFIGURATION_SUCCESS,
  DELETE_EMAIL_CONFIGURATION_ERROR,
  CLEAR_EMAIL_CONFIGURATION_FLAG
} from "../type/EmailConfigurationType";
import {
  EmailConfigurations,
  EmailConfiguration
} from "../../graphql/query/EmailConfigurationQuery";
import {
  CreateEmailConfiguration,
  UpdateEmailConfiguration,
  DeleteEmailConfiguration
} from "../../graphql/mutation/EmailConfigurationMutation";

// Get All Email Configuration
export const getAllEmailConfigurationRequest = () => {
  return {
    type: GET_ALL_EMAIL_CONFIGURATION_REQUEST
  };
};

export const getAllEmailConfigurationSuccess = payload => {
  return {
    type: GET_ALL_EMAIL_CONFIGURATION_SUCCESS,
    payload
  };
};

export const getAllEmailConfigurationError = payload => {
  return {
    type: GET_ALL_EMAIL_CONFIGURATION_ERROR,
    payload
  };
};

export const getAllEmailConfiguration = client => {
  return dispatch => {
    dispatch(getAllEmailConfigurationRequest);
    return client
      .query({
        query: EmailConfigurations,
        fetchPolicy: "no-cache"
      })
      .then(result => dispatch(getAllEmailConfigurationSuccess(result)))
      .catch(error => dispatch(getAllEmailConfigurationError(error)));
  };
};

// Get Email Configuration
export const getEmailConfigurationRequest = payload => {
  return {
    type: GET_EMAIL_CONFIGURATION_REQUEST,
    payload
  };
};

export const getEmailConfigurationSuccess = payload => {
  return {
    type: GET_EMAIL_CONFIGURATION_SUCCESS,
    payload
  };
};

export const getEmailConfigurationError = payload => {
  return {
    type: GET_EMAIL_CONFIGURATION_ERROR,
    payload
  };
};

export const getEmailConfiguration = (values, client) => {
  return dispatch => {
    dispatch(getEmailConfigurationRequest);
    return client
      .query({
        variables: values,
        query: EmailConfiguration,
        fetchPolicy: "no-cache"
      })
      .then(result => dispatch(getEmailConfigurationSuccess(result)))
      .catch(error => dispatch(getEmailConfigurationError(error)));
  };
};

// Create Email Configuration
export const createEmailConfigurationRequest = payload => {
  return {
    type: CREATE_EMAIL_CONFIGURATION_REQUEST,
    payload
  };
};

export const createEmailConfigurationSuccess = payload => {
  return {
    type: CREATE_EMAIL_CONFIGURATION_SUCCESS,
    payload
  };
};

export const createEmailConfigurationError = payload => {
  return {
    type: CREATE_EMAIL_CONFIGURATION_ERROR,
    payload
  };
};

export const createEmailConfiguration = (values, client) => {
  return dispatch => {
    dispatch(createEmailConfigurationRequest);
    return client
      .mutate({
        variables: values,
        mutation: CreateEmailConfiguration
      })
      .then(result => dispatch(createEmailConfigurationSuccess(result)))
      .catch(error => dispatch(createEmailConfigurationError(error)));
  };
};

// Update Email Configuration
export const updateEmailConfigurationRequest = payload => {
  return {
    type: UPDATE_EMAIL_CONFIGURATION_REQUEST,
    payload
  };
};

export const updateEmailConfigurationSuccess = payload => {
  return {
    type: UPDATE_EMAIL_CONFIGURATION_SUCCESS,
    payload
  };
};

export const updateEmailConfigurationError = payload => {
  return {
    type: UPDATE_EMAIL_CONFIGURATION_ERROR,
    payload
  };
};

export const updateEmailConfiguration = (values, client) => {
  return dispatch => {
    dispatch(updateEmailConfigurationRequest);
    return client
      .mutate({
        variables: values,
        mutation: UpdateEmailConfiguration
      })
      .then(result => dispatch(updateEmailConfigurationSuccess(result)))
      .catch(error => dispatch(updateEmailConfigurationError(error)));
  };
};

// Delete Email Configuration
export const deleteEmailConfigurationRequest = payload => {
  return {
    type: DELETE_EMAIL_CONFIGURATION_REQUEST,
    payload
  };
};

export const deleteEmailConfigurationSuccess = payload => {
  return {
    type: DELETE_EMAIL_CONFIGURATION_SUCCESS,
    payload
  };
};

export const deleteEmailConfigurationError = payload => {
  return {
    type: DELETE_EMAIL_CONFIGURATION_ERROR,
    payload
  };
};

export const deleteEmailConfiguration = (values, client) => {
  return dispatch => {
    dispatch(deleteEmailConfigurationRequest);
    return client
      .mutate({
        variables: values,
        mutation: DeleteEmailConfiguration
      })
      .then(result => dispatch(deleteEmailConfigurationSuccess(result)))
      .catch(error => dispatch(deleteEmailConfigurationError(error)));
  };
};

export const clearEmailConfigurationFlag = () => {
  return {
    type: CLEAR_EMAIL_CONFIGURATION_FLAG
  };
};
