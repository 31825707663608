import gql from "graphql-tag";

export const LogIn = gql`
  mutation Login($email: String, $password: String) {
    Login(email: $email, password: $password) {
      user {
        name
        email
      }
      token
    }
  }
`;