import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Button from "components/CustomButtons/Button.js";
import CardIcon from "components/Card/CardIcon";

class Lobby extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>       
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <Card>
              <form onSubmit={this.props.handleSubmit}>
                <CardHeader color="info" text>
                  <CardIcon color="info">
                    <ChatIcon />
                  </CardIcon>
                </CardHeader>
                <CardBody className={classes.subscriptionCard}>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>
                        Description
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        id="field"
                        margin="normal"
                        value={this.props.username}
                        onChange={this.props.handleUsernameChange}
                        required
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ paddingTop: 10 }}
                      >
                        Room name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        id="room"
                        margin="normal"
                        value={this.props.roomName}
                        onChange={this.props.handleRoomNameChange}
                        required
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="sm"
                    color="info"
                    style={{ fontSize: "0.95rem", marginTop: 30 }}                   
                  >
                    Sign In
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
Lobby.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Lobby);
