import gql from "graphql-tag";

export const GetDashboard = gql`
  query dashboard {
    dashboard {
      total_users
      guest_users
      total_payments
    }
  }
`;
