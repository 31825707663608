// GET All QUESTION
export const GET_ALL_QUESTIONS_REQUEST = "GET_ALL_QUESTIONS_REQUEST";
export const GET_ALL_QUESTIONS_SUCCESS = "GET_ALL_QUESTIONS_SUCCESS";
export const GET_ALL_QUESTIONS_ERROR = "GET_ALL_QUESTIONS_ERROR";

// GET QUESTION
export const GET_QUESTION_REQUEST = "GET_QUESTION_REQUEST";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_ERROR = "GET_QUESTION_ERROR";

// Create QUESTION
export const CREATE_QUESTION_REQUEST = "CREATE_QUESTION_REQUEST";
export const CREATE_QUESTION_SUCCESS = "CREATE_QUESTION_SUCCESS";
export const CREATE_QUESTION_ERROR = "CREATE_QUESTION_ERROR";

// Update QUESTION
export const UPDATE_QUESTION_REQUEST = "UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_ERROR = "UPDATE_QUESTION_ERROR";

// Delete QUESTION
export const DELETE_QUESTION_REQUEST = "DELETE_QUESTION_REQUEST";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_ERROR = "DELETE_QUESTION_ERROR";

//clear QUESTION flag

export const CLEAR_QUESTIONS_FLAG = "CLEAR_QUESTIONS_FLAG";
