import React, { Component } from "react";
import Login from "./ChatLogin";
import ChatApp from "./ChatApp";
import "@progress/kendo-theme-material/dist/all.css";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: localStorage.getItem("ChatUserName") || "",
      loggedIn: localStorage.getItem("LoggedInChat") || false,
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
  }

  componentDidMount() {
    this.setState({ username: "" })
  }

  handleLogin(event) {
    event.preventDefault();
    this.setState({ loggedIn: true });
    localStorage.setItem("LoggedInChat", true);
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
    localStorage.setItem("ChatUserName", event.target.value);
  }

  handleClose = () => {
    this.setState({ loggedIn: false });
    localStorage.removeItem("LoggedInChat");
    localStorage.removeItem("ChatUserName");
  };

  render() {
    let loginOrChat;
    if (this.state.loggedIn) {
      loginOrChat = <ChatApp username={this.state.username} handleClose={this.handleClose} />;
    } else {
      loginOrChat = (
        <Login
          handleLogin={this.handleLogin}
          handleUsernameChange={this.handleUsernameChange}
          username={this.state.username}
        />
      );
    }
    return (
      <div className="container">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {loginOrChat}
        </div>
      </div>
    );
  }
}

export default Chat;
