import {
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  CLEAR_LOG_IN_FLAG
} from "../type/LoginType";
import { LogIn } from "../../graphql/mutation/LoginMutation";

export const logInRequest = payload => {
  return {
    type: LOG_IN_REQUEST,
    payload
  };
};

export const logInSuccess = payload => {
  return {
    type: LOG_IN_SUCCESS,
    payload
  };
};

export const logInError = payload => {
  return {
    type: LOG_IN_ERROR,
    payload
  };
};

export const logIn = (values, client) => {
  return dispatch => {
    dispatch(logInRequest);
    return client
      .mutate({
        variables: values,
        mutation: LogIn
      })
      .then(result => dispatch(logInSuccess(result)))
      .catch(error => dispatch(logInError(error)));
  };
};

export const clearLoginFlag = () => {
  return {
    type: CLEAR_LOG_IN_FLAG
  };
};
