import {
  GET_ALL_QUESTIONS_REQUEST,
  GET_ALL_QUESTIONS_SUCCESS,
  GET_ALL_QUESTIONS_ERROR,
  GET_QUESTION_REQUEST,
  GET_QUESTION_ERROR,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_ERROR,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_ERROR,
  GET_QUESTION_SUCCESS,
  CREATE_QUESTION_ERROR,
  CLEAR_QUESTIONS_FLAG
} from "../type/QuestionType";

const initialState = {
  questionsList: [],
  getAllQuestionsSuccess: false,
  getAllQuestionsError: false,

  questionData: [],
  getQuestionSuccess: false,
  getQuestionError: false,

  createQuestionData: [],
  createQuestionSuccess: false,
  createQuestionError: false,

  updateQuestionData: [],
  updateQuestionSuccess: false,
  updateQuestionError: false,

  deleteQuestionData: [],
  deleteQuestionSuccess: false,
  deleteQuestionError: false
};

export default function questionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_QUESTIONS_REQUEST:
      return {
        ...state,
        getAllQuestionsSuccess: false,
        getAllQuestionsError: false
      };
    case GET_ALL_QUESTIONS_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        getAllQuestionsSuccess: true,
        getAllQuestionsError: false,
        questionsList: payload.data.questionnaires
      };
    case GET_ALL_QUESTIONS_ERROR:
      return {
        ...state,
        getAllQuestionsError: true,
        getAllQuestionsSuccess: false
      };
    case GET_QUESTION_REQUEST:
      return {
        ...state,
        getQuestionError: false,
        getQuestionSuccess: false
      };
    case GET_QUESTION_SUCCESS:
      return {
        ...state,
        getQuestionSuccess: true,
        getQuestionError: false,
        questionData: action.payload.data.questionnaire
      };
    case GET_QUESTION_ERROR:
      return {
        ...state,
        getQuestionError: true,
        getQuestionSuccess: false
      };
    case CREATE_QUESTION_REQUEST:
      return {
        ...state,
        createQuestionSuccess: false,
        createQuestionError: false
      };
    case CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        createQuestionData: action.payload.data,
        createQuestionSuccess: true,
        createQuestionError: false
      };
    case CREATE_QUESTION_ERROR:
      return {
        ...state,
        createQuestionError: true,
        createQuestionSuccess: false
      };
    case UPDATE_QUESTION_REQUEST:
      return {
        ...state,
        updateQuestionSuccess: false,
        updateQuestionError: false
      };
    case UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        updateQuestionSuccess: true,
        updateQuestionError: false,
        updateQuestionData: action.payload.data
      };
    case UPDATE_QUESTION_ERROR:
      return {
        ...state,
        updateQuestionError: true,
        updateQuestionSuccess: false
      };
    case DELETE_QUESTION_REQUEST:
      return {
        ...state,
        deleteQuestionSuccess: false,
        deleteQuestionError: false
      };
    case DELETE_QUESTION_SUCCESS:      
      return {
        ...state,
        deleteQuestionSuccess: true,
        deleteQuestionError: false,
        deleteQuestionData: action.payload.data,
        questionsList: state.questionsList.filter(item => item.uuid !== action.payload.data.DeleteQuestionnaire.uuid)
      };
    case DELETE_QUESTION_ERROR:
      return {
        ...state,
        deleteQuestionError: true,
        deleteQuestionSuccess: false
      };
    case CLEAR_QUESTIONS_FLAG:
      return {
        ...state,
        getQuestionSuccess: false,
        getQuestionError: false,
        getAllQuestionSuccess: false,
        getAllQuestionError: false,
        deleteQuestionSuccess: false,
        deleteQuestionError: false,
        updateQuestionError: false,
        updateQuestionSuccess: false,
        createQuestionSuccess: false,
        createQuestionError: false
      };
    default:
      return {
        ...state
      };
  }
}
