import {
  GET_ALL_CMS_REQUEST,
  GET_ALL_CMS_SUCCESS,
  GET_ALL_CMS_ERROR,
  GET_CMS_REQUEST,
  GET_CMS_SUCCESS,
  GET_CMS_ERROR,
  CREATE_CMS_REQUEST,
  CREATE_CMS_ERROR,
  UPDATE_CMS_REQUEST,
  UPDATE_CMS_SUCCESS,
  UPDATE_CMS_ERROR,
  DELETE_CMS_REQUEST,
  DELETE_CMS_SUCCESS,
  DELETE_CMS_ERROR,
  CREATE_CMS_SUCCESS,
  CLEAR_CMS_FLAG
} from "../type/CmsType";
import { allCms, cms } from "../../graphql/query/CmsQuery";
import {
  CreateCms,
  UpdateCms,
  DeleteCms
} from "../../graphql/mutation/CmsMutation";

// Get All CMS List
export const getAllCmsRequest = () => {
  return {
    type: GET_ALL_CMS_REQUEST
  };
};

export const getAllCmsSuccess = payload => {
  return {
    type: GET_ALL_CMS_SUCCESS,
    payload
  };
};

export const getAllCmsError = payload => {
  return {
    type: GET_ALL_CMS_ERROR,
    payload
  };
};

export const getAllCmsPage = client => {
  return dispatch => {
    dispatch(getAllCmsRequest);
    return client
      .query({
        query: allCms,
        fetchPolicy: "no-cache"
      })
      .then(result => dispatch(getAllCmsSuccess(result)))
      .catch(error => dispatch(getAllCmsError(error)));
  };
};

// Get CMS
export const getCmsRequest = payload => {
  return {
    type: GET_CMS_REQUEST,
    payload
  };
};

export const getCmsSuccess = payload => {
  return {
    type: GET_CMS_SUCCESS,
    payload
  };
};

export const getCmsError = payload => {
  return {
    type: GET_CMS_ERROR,
    payload
  };
};

export const getCmsPage = (values, client) => {
  return dispatch => {
    dispatch(getCmsRequest);
    return client
      .query({
        variables: values,
        query: cms
      })
      .then(result => dispatch(getCmsSuccess(result)))
      .catch(error => dispatch(getCmsError(error)));
  };
};

// Create CMS Page
export const createCmsRequest = payload => {
  return {
    type: CREATE_CMS_REQUEST,
    payload
  };
};

export const createCmsSuccess = payload => {
  return {
    type: CREATE_CMS_SUCCESS,
    payload
  };
};

export const createCmsError = payload => {
  return {
    type: CREATE_CMS_ERROR,
    payload
  };
};

export const createCmsPage = (values, client) => {
  return dispatch => {
    dispatch(createCmsRequest);
    return client
      .mutate({
        variables: values,
        mutation: CreateCms
      })
      .then(result => dispatch(createCmsSuccess(result)))
      .catch(error => dispatch(createCmsError(error)));
  };
};

// Update Cms
export const updateCmsRequest = payload => {
  return {
    type: UPDATE_CMS_REQUEST,
    payload
  };
};

export const updateCmsSuccess = payload => {
  return {
    type: UPDATE_CMS_SUCCESS,
    payload
  };
};

export const updateCmsError = payload => {
  return {
    type: UPDATE_CMS_ERROR,
    payload
  };
};

export const updateCmsPage = (values, client) => {
  return dispatch => {
    dispatch(updateCmsRequest);
    return client
      .mutate({
        variables: values,
        mutation: UpdateCms
      })
      .then(result => dispatch(updateCmsSuccess(result)))
      .catch(error => dispatch(updateCmsError(error)));
  };
};

// Delete CMS page
export const deleteCmsRequest = payload => {
  return {
    type: DELETE_CMS_REQUEST,
    payload
  };
};

export const deleteCmsSuccess = payload => {
  return {
    type: DELETE_CMS_SUCCESS,
    payload
  };
};

export const deleteCmsError = payload => {
  return {
    type: DELETE_CMS_ERROR,
    payload
  };
};

export const deleteCmsPage = (values, client) => {
  return dispatch => {
    dispatch(deleteCmsRequest);
    return client
      .mutate({
        variables: values,
        mutation: DeleteCms
      })
      .then(result => dispatch(deleteCmsSuccess(result)))
      .catch(error => dispatch(deleteCmsError(error)));
  };
};

//clear cms flag
export const clearCmsFlag = () => {
  return {
    type: CLEAR_CMS_FLAG
  };
};
