import {
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
  CLEAR_DASHBOARD_FLAG
} from "../type/DashboardType";
import { GetDashboard } from "graphql/query/DashboardQuery";

export const getDashboardRequest = () => {
  return {
    type: GET_DASHBOARD_REQUEST,
  };
};

export const getDashboardSuccess = payload => {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload
  };
};

export const getDashboardError = payload => {
  return {
    type: GET_DASHBOARD_ERROR,
    payload
  };
};

export const getDashboard = (client) => {
  return dispatch => {
    dispatch(getDashboardRequest);
    return client
      .query({
        query: GetDashboard,
        fetchPolicy: "no-cache"
      })
      .then(result => dispatch(getDashboardSuccess(result)))
      .catch(error => dispatch(getDashboardError(error)));
  };
};

export const clearDashboard = () => {
  return {
    type: CLEAR_DASHBOARD_FLAG
  };
};
