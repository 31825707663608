import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { ApolloClient, InMemoryCache, HttpLink } from "apollo-boost";
import { setContext } from "apollo-link-context";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import ManageQuestion from "views/Questions/ManageQuestion";
import Login from "views/Pages/LoginPage";
import { store } from "redux/Store"
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_CONFIG
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const hist = createBrowserHistory();

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router history={hist}>
        <Switch>
          <Route path="/rtl" component={RtlLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route path="/admin" component={AdminLayout} />
          {/* <Route path={"/manage-cms"} component={CMS} /> */}
          <Route exact path={"/manage-question"} component={ManageQuestion} />
          <Route exact path={"/"} component={Login} />
        </Switch>
      </Router>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
