import gql from "graphql-tag";

// Get Email Template

export const EmailTemplate = gql`
  query emailTemplate($uuid: String) {
    emailTemplate(uuid: $uuid) {
      uuid
      name
      subject
      variable
      sender_email
      smtp_configuration_id
      email_body
      is_default
      encryption_type
      message
    }
  }
`;

// Get All Email Template

export const EmailTemplates = gql`
  query emailTemplates {
    emailTemplates {
      uuid
      name
      subject
      variable
      sender_email
      smtp_configuration_id
      email_body
      is_default
      encryption_type
      message
    }
  }
`;

// Get Template Variable

export const EmailVariables = gql`
  query emailVariables {
    emailVariables
  }
`;
