import gql from "graphql-tag";

export const AllQuestions = gql`
  query questionnaires {
    questionnaires {
      uuid
      question      
      message
    }
  }
`;

export const Question = gql`
  query questionnaire($uuid: String) {
    questionnaire(uuid: $uuid) {
      uuid
      question      
      message
    }
  }
`;
