import gql from "graphql-tag";

// Create Email Template
export const CreateEmailTemplate = gql`
  mutation CreateEmailTemplate(
    $name: String
    $subject: String
    $variable: String
    $sender_email: String
    $smtp_configuration_id: Int
    $email_body: String
    $is_default: Int
    $encryption_type: String
  ) {
    CreateEmailTemplate(
      name: $name
      subject: $subject
      variable: $variable
      sender_email: $sender_email
      smtp_configuration_id: $smtp_configuration_id
      email_body: $email_body
      is_default: $is_default
      encryption_type: $encryption_type
    ) {
      uuid
      name
      subject
      variable
      sender_email
      smtp_configuration_id
      email_body
      is_default
      encryption_type
      message
    }
  }
`;

// Update Email Template
export const UpdateEmailTemplate = gql`
  mutation UpdateEmailTemplate(
    $uuid: String!
    $name: String
    $subject: String
    $variable: String
    $sender_email: String
    $smtp_configuration_id: Int
    $email_body: String
    $is_default: Int
    $encryption_type: String
  ) {
    UpdateEmailTemplate(
      uuid: $uuid
      name: $name
      subject: $subject
      variable: $variable
      sender_email: $sender_email
      smtp_configuration_id: $smtp_configuration_id
      email_body: $email_body
      is_default: $is_default
      encryption_type: $encryption_type
    ) {
      uuid
      name
      subject
      variable
      sender_email
      smtp_configuration_id
      email_body
      is_default
      encryption_type
      message
    }
  }
`;

// Delete Email Template
export const DeleteEmailTemplate = gql`
  mutation DeleteEmailTemplate($uuid: String!) {
    DeleteEmailTemplate(uuid: $uuid) {
      uuid
      name
      subject
      variable
      sender_email
      smtp_configuration_id
      email_body
      is_default
      encryption_type
      message
    }
  }
`;
