// Get All Email Configurations
export const GET_ALL_EMAIL_CONFIGURATION_REQUEST =
  "GET_ALL_EMAIL_CONFIGURATION_REQUEST";
export const GET_ALL_EMAIL_CONFIGURATION_SUCCESS =
  "GET_ALL_EMAIL_CONFIGURATION_SUCCESS";
export const GET_ALL_EMAIL_CONFIGURATION_ERROR =
  "GET_ALL_EMAIL_CONFIGURATION_ERROR";

//Get Email Configuration
export const GET_EMAIL_CONFIGURATION_REQUEST =
  "GET_EMAIL_CONFIGURATION_REQUEST";
export const GET_EMAIL_CONFIGURATION_SUCCESS =
  "GET_EMAIL_CONFIGURATION_SUCCESS";
export const GET_EMAIL_CONFIGURATION_ERROR = "GET_EMAIL_CONFIGURATION_ERROR";

//Create Email Configuration
export const CREATE_EMAIL_CONFIGURATION_REQUEST =
  "CREATE_EMAIL_CONFIGURATION_REQUEST";
export const CREATE_EMAIL_CONFIGURATION_SUCCESS =
  "CREATE_EMAIL_CONFIGURATION_SUCCESS";
export const CREATE_EMAIL_CONFIGURATION_ERROR =
  "CREATE_EMAIL_CONFIGURATION_ERROR";

//Update Email Configuration
export const UPDATE_EMAIL_CONFIGURATION_REQUEST =
  "UPDATE_EMAIL_CONFIGURATION_REQUEST";
export const UPDATE_EMAIL_CONFIGURATION_SUCCESS =
  "UPDATE_EMAIL_CONFIGURATION_SUCCESS";
export const UPDATE_EMAIL_CONFIGURATION_ERROR =
  "UPDATE_EMAIL_CONFIGURATION_ERROR";

//Delete Email Configuration
export const DELETE_EMAIL_CONFIGURATION_REQUEST =
  "DELETE_EMAIL_CONFIGURATION_REQUEST";
export const DELETE_EMAIL_CONFIGURATION_SUCCESS =
  "DELETE_EMAIL_CONFIGURATION_SUCCESS";
export const DELETE_EMAIL_CONFIGURATION_ERROR =
  "DELETE_EMAIL_CONFIGURATION_ERROR";

export const CLEAR_EMAIL_CONFIGURATION_FLAG = "CLEAR_EMAIL_CONFIGURATION_FLAG";
