import gql from "graphql-tag";

//Create Email Configuration Mutation
export const CreateEmailConfiguration = gql`
  mutation CreateEmailConfiguration(
    $hostname: String
    $server_ip: String
    $port: Int
    $smtp_details: String
    $smtp_password: String
    $encryption_type: String
    $sender_email: String
    $sender_name: String
  ) {
    CreateEmailConfiguration(
      hostname: $hostname
      server_ip: $server_ip
      port: $port
      smtp_details: $smtp_details
      smtp_password: $smtp_password
      encryption_type: $encryption_type
      sender_email: $sender_email
      sender_name: $sender_name
    ) {
      uuid
      hostname
      server_ip
      port
      smtp_details
      smtp_password
      encryption_type
      sender_email
      sender_name
      message
    }
  }
`;

//Update Email Configuration Mutation
export const UpdateEmailConfiguration = gql`
  mutation UpdateEmailConfiguration(
    $uuid: String!
    $hostname: String
    $server_ip: String
    $port: Int
    $smtp_details: String
    $smtp_password: String
    $encryption_type: String
    $sender_email: String
    $sender_name: String
  ) {
    UpdateEmailConfiguration(
      uuid: $uuid
      hostname: $hostname
      server_ip: $server_ip
      port: $port
      smtp_details: $smtp_details
      smtp_password: $smtp_password
      encryption_type: $encryption_type
      sender_email: $sender_email
      sender_name: $sender_name
    ) {
      uuid
      hostname
      server_ip
      port
      smtp_details
      smtp_password
      encryption_type
      sender_email
      sender_name
      message
    }
  }
`;

export const DeleteEmailConfiguration = gql`
  mutation DeleteEmailConfiguration($uuid: String!) {
    DeleteEmailConfiguration(uuid: $uuid) {
      uuid
      hostname
      server_ip
      port
      smtp_details
      smtp_password
      encryption_type
      sender_email
      sender_name
    }
  }
`;
