import {
  GET_ALL_CMS_REQUEST,
  GET_ALL_CMS_SUCCESS,
  GET_ALL_CMS_ERROR,
  GET_CMS_REQUEST,
  GET_CMS_ERROR,
  CREATE_CMS_REQUEST,
  CREATE_CMS_SUCCESS,
  UPDATE_CMS_REQUEST,
  UPDATE_CMS_SUCCESS,
  UPDATE_CMS_ERROR,
  DELETE_CMS_REQUEST,
  DELETE_CMS_SUCCESS,
  DELETE_CMS_ERROR,
  GET_CMS_SUCCESS,
  CREATE_CMS_ERROR,
  CLEAR_CMS_FLAG
} from "../type/CmsType";

const initialState = {
  cmsList: [],
  getAllCmsSuccess: false,
  getAllCmsError: false,

  cmsData: [],
  getCmsSuccess: false,
  getCmsError: false,

  createCmsData: [],
  createCmsSuccess: false,
  createCmsError: false,

  updateCmsData: [],
  updateCmsSuccess: false,
  updateCmsError: false,

  deleteCmsData: [],
  deleteCmsSuccess: false,
  deleteCmsError: false,  
};

export default function cmsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CMS_REQUEST:
      return {
        ...state,
        getAllCmsSuccess: false,
        getAllCmsError: false
      };
    case GET_ALL_CMS_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        getAllCmsSuccess: true,
        cmsList: payload.data.allCms,
        getAllCmsError: false
      };
    case GET_ALL_CMS_ERROR:
      return {
        ...state,
        getAllCmsError: true,
        getAllCmsSuccess: false
      };
    case GET_CMS_REQUEST:
      return {
        ...state,
        getCmsError: false,
        getCmsSuccess: false
      };
    case GET_CMS_SUCCESS:
      return {
        ...state,
        getCmsSuccess: true,
        getCmsError: false,
        cmsData: action.payload.data.cms
      };
    case GET_CMS_ERROR:
      return {
        ...state,
        getCmsError: true,
        getCmsSuccess: false
      };
    case CREATE_CMS_REQUEST:
      return {
        ...state,
        createCmsSuccess: false,
        createCmsError: false
      };
    case CREATE_CMS_SUCCESS:
      return {
        ...state,
        createCmsData: action.payload.data.CreateCms,
        createCmsSuccess: true,
        createCmsError: false
      };
    case CREATE_CMS_ERROR:
      return {
        ...state,
        createCmsError: true,
        createCmsSuccess: false
      };
    case UPDATE_CMS_REQUEST:
      return {
        ...state,
        updateCmsSuccess: false,
        updateCmsError: false,
      };
    case UPDATE_CMS_SUCCESS:
      return {
        ...state,
        updateCmsSuccess: true,
        updateCmsError: false,
        updateCmsData: action.payload.data.Cms,
      };
    case UPDATE_CMS_ERROR:
      return {
        ...state,
        updateCmsError: true,
        updateCmsSuccess: false
      };
    case DELETE_CMS_REQUEST:
      return {
        ...state,
        deleteCmsSuccess: false,
        deleteCmsError: false,
      };
    case DELETE_CMS_SUCCESS:      
      return {
        ...state,
        deleteCmsSuccess: true,
        deleteCmsError: false,
        deleteCmsData: action.payload.data.Cms,
        cmsList: state.cmsList.filter(item => item.uuid !== action.payload.data.DeleteCms.uuid)
      };
    case DELETE_CMS_ERROR:
      return {
        ...state,
        deleteCmsError: true,
        deleteCmsSuccess: false
      };
    case CLEAR_CMS_FLAG: 
      return {
        ...state,
        getCmsSuccess: false,
        getCmsError: false,        
        getAllCmsSuccess: false,
        getAllCmsError: false,
        deleteCmsSuccess: false,
        deleteCmsError: false,
        updateCmsError: false,
        updateCmsSuccess: false,
        createCmsSuccess: false,
        createCmsError: false
    }
    default:
      return {
        ...state
      };
  }
}
