// GET All CMS
export const GET_ALL_CMS_REQUEST = "GET_ALL_CMS_REQUEST";
export const GET_ALL_CMS_SUCCESS = "GET_ALL_CMS_SUCCESS";
export const GET_ALL_CMS_ERROR = "GET_ALL_CMS_ERROR";

// GET CMS
export const GET_CMS_REQUEST = "GET_CMS_REQUEST";
export const GET_CMS_SUCCESS = "GET_CMS_SUCCESS";
export const GET_CMS_ERROR = "GET_CMS_ERROR";

// Create CMS
export const CREATE_CMS_REQUEST = "CREATE_CMS_REQUEST";
export const CREATE_CMS_SUCCESS = "CREATE_CMS_SUCCESS";
export const CREATE_CMS_ERROR = "CREATE_CMS_ERROR";

// Update CMS
export const UPDATE_CMS_REQUEST = "UPDATE_CMS_REQUEST";
export const UPDATE_CMS_SUCCESS = "UPDATE_CMS_SUCCESS";
export const UPDATE_CMS_ERROR = "UPDATE_CMS_ERROR";

// Delete CMS
export const DELETE_CMS_REQUEST = "DELETE_CMS_REQUEST";
export const DELETE_CMS_SUCCESS = "DELETE_CMS_SUCCESS";
export const DELETE_CMS_ERROR = "DELETE_CMS_ERROR";

//clear cms flag

export const CLEAR_CMS_FLAG = "CLEAR_CMS_FLAG"; 