import gql from "graphql-tag";

// Get CMS List
export const allCms = gql`
  query allCms {
    allCms {
      uuid
      name
      description
      page_description
      status
      message
    }
  }
`;

// Get CMS
export const cms = gql`
  query cms($uuid: String) {
    cms(uuid: $uuid) {
      name
      description
      page_description
    }
  }
`;
