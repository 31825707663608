import gql from "graphql-tag";

// Create Question
export const CreateQuestion = gql`
  mutation CreateQuestionnaire(
    $question: String  
  ) {
    CreateQuestionnaire(
      question: $question     
    ) {
      uuid
      question      
    }
  }
`;

//Update Question
export const UpdateQuestion = gql`
  mutation UpdateQuestionnaire(
    $uuid: String!
    $question: String    
  ) {
    UpdateQuestionnaire(
      uuid: $uuid
      question: $question      
    ) {
      uuid
      question      
    }
  }
`;

//Delete Question
export const DeleteQuestion = gql`
  mutation DeleteQuestionnaire($uuid: String!) {
    DeleteQuestionnaire(uuid: $uuid) {
      uuid
      question    
    }
  }
`;
