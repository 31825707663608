import {
  GET_ALL_QUESTIONS_REQUEST,
  GET_ALL_QUESTIONS_SUCCESS,
  GET_ALL_QUESTIONS_ERROR,
  GET_QUESTION_REQUEST,
  GET_QUESTION_ERROR,
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_ERROR,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_ERROR,
  GET_QUESTION_SUCCESS,
  CREATE_QUESTION_ERROR,
  CLEAR_QUESTIONS_FLAG
} from "../type/QuestionType";
import { AllQuestions, Question } from "../../graphql/query/QuestionQuery";
import {
  CreateQuestion,
  UpdateQuestion,
  DeleteQuestion
} from "../../graphql/mutation/QuestionMutation";

// Get All Questions List
export const getAllQuestionsRequest = () => {
  return {
    type: GET_ALL_QUESTIONS_REQUEST
  };
};

export const getAllQuestionsSuccess = payload => {
  return {
    type: GET_ALL_QUESTIONS_SUCCESS,
    payload
  };
};

export const getAllQuestionsError = payload => {
  return {
    type: GET_ALL_QUESTIONS_ERROR,
    payload
  };
};

export const getAllQuestions = client => {
  return dispatch => {
    dispatch(getAllQuestionsRequest);
    return client
      .query({
        query: AllQuestions,
        fetchPolicy: "no-cache"
      })
      .then(result => dispatch(getAllQuestionsSuccess(result)))
      .catch(error => dispatch(getAllQuestionsError(error)));
  };
};

// Get Question
export const getQuestionRequest = payload => {
  return {
    type: GET_QUESTION_REQUEST,
    payload
  };
};

export const getQuestionSuccess = payload => {
  return {
    type: GET_QUESTION_SUCCESS,
    payload
  };
};

export const getQuestionError = payload => {
  return {
    type: GET_QUESTION_ERROR,
    payload
  };
};

export const getQuestion = (values, client) => {
  return dispatch => {
    dispatch(getQuestionRequest);
    return client
      .query({
        variables: values,
        query: Question,
        fetchPolicy: "no-cache"
      })
      .then(result => dispatch(getQuestionSuccess(result)))
      .catch(error => dispatch(getQuestionError(error)));
  };
};

// Create Question
export const createQuestionRequest = payload => {
  return {
    type: CREATE_QUESTION_REQUEST,
    payload
  };
};

export const createQuestionSuccess = payload => {
  return {
    type: CREATE_QUESTION_SUCCESS,
    payload
  };
};

export const createQuestionError = payload => {
  return {
    type: CREATE_QUESTION_ERROR,
    payload
  };
};

export const createQuestion = (values, client) => {
  return dispatch => {
    dispatch(createQuestionRequest);
    return client
      .mutate({
        variables: values,
        mutation: CreateQuestion
      })
      .then(result => dispatch(createQuestionSuccess(result)))
      .catch(error => dispatch(createQuestionError(error)));
  };
};

// Update Question
export const updateQuestionRequest = payload => {
  return {
    type: UPDATE_QUESTION_REQUEST,
    payload
  };
};

export const updateQuestionSuccess = payload => {
  return {
    type: UPDATE_QUESTION_SUCCESS,
    payload
  };
};

export const updateQuestionError = payload => {
  return {
    type: UPDATE_QUESTION_ERROR,
    payload
  };
};

export const updateQuestion = (values, client) => {
  return dispatch => {
    dispatch(updateQuestionRequest);
    return client
      .mutate({
        variables: values,
        mutation: UpdateQuestion
      })
      .then(result => dispatch(updateQuestionSuccess(result)))
      .catch(error => dispatch(updateQuestionError(error)));
  };
};

// Delete Question
export const deleteQuestionRequest = payload => {
  return {
    type: DELETE_QUESTION_REQUEST,
    payload
  };
};

export const deleteQuestionSuccess = payload => {
  return {
    type: DELETE_QUESTION_SUCCESS,
    payload
  };
};

export const deleteQuestionError = payload => {
  return {
    type: DELETE_QUESTION_ERROR,
    payload
  };
};

export const deleteQuestion = (values, client) => {
  return dispatch => {
    dispatch(deleteQuestionRequest);
    return client
      .mutate({
        variables: values,
        mutation: DeleteQuestion
      })
      .then(result => dispatch(deleteQuestionSuccess(result)))
      .catch(error => dispatch(deleteQuestionError(error)));
  };
};

//Clear Questions Flag
export const clearQuestionsFlag = () => {
  return {
    type: CLEAR_QUESTIONS_FLAG
  };
};
