import gql from "graphql-tag";

export const GetUsers = gql`
  query users {
    users {
      uuid
      name
      mobile_no
      patient {
        score
      }
      email
      paid
      is_guest
      payments_count
      message
    }
  }
`;
