/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { TextField, MenuItem, Tooltip, IconButton } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const CardText = lazy(() => import("components/Card/CardText.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const CardFooter = lazy(() => import("components/Card/CardFooter"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Snackbar = lazy(() => import("components/Snackbar/SnackbarContent"));

import {
  getEmailTemplate,
  clearEmailTemplateFlag,
  createEmailTemplate,
  updateEmailTemplate,
  getEmailVariable,
} from "../../redux/actions/EmailTemplateAction";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

class ManageEmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateTitle: "",
      templateSubject: "",
      content: "",
      patientData: [],
      variableData: [],
      variable: "",
      display: false,
      id: "",
      uuid: props.location.state && props.location.state.uuid,
      isEdit: props.location.state && props.location.state.isEdit,
      openSnackBar: false,
      snackBarVariant: "error",
      response: "",
    };
  }

  componentDidMount() {
    const { getEmailTemplate, client, getEmailVariable } = this.props;
    const { uuid } = this.state;
    uuid && getEmailTemplate({ uuid }, client);
    getEmailVariable(client);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      getEmailTemplateSuccess,
      emailTemplateData,
      clearEmailTemplateFlag,
      createEmailTemplateSuccess,
      createEmailTemplateError,
      updateEmailTemplateSuccess,
      updateEmailTemplateError,
      emailVariableData,
      getEmailVariableSuccess,
      getEmailVariableError,
    } = props;
    if (getEmailTemplateSuccess) {
      clearEmailTemplateFlag();
      return {
        variable: emailTemplateData.variable,
        templateTitle: emailTemplateData.name,
        templateSubject: emailTemplateData.subject,
        content: emailTemplateData.email_body,
      };
    }

    if (createEmailTemplateSuccess) {
      clearEmailTemplateFlag();
      props.history.push("/admin/email-Template");
    }

    if (createEmailTemplateError) {
      clearEmailTemplateFlag();
      return {
        openSnackBar: true,
        response: "Something went wrong, please try again!",
        snackBarVariant: "error",
      };
    }

    if (updateEmailTemplateSuccess) {
      clearEmailTemplateFlag();
      props.history.push("/admin/email-Template");
    }

    if (updateEmailTemplateError) {
      clearEmailTemplateFlag();
      return {
        openSnackBar: true,
        response: "Something went wrong, please try again!",
        snackBarVariant: "error",
      };
    }

    if (getEmailVariableSuccess) {
      console.log(emailVariableData, "emailVariable");

      clearEmailTemplateFlag();
      return {
        variableData: emailVariableData,
      };
    }

    if (getEmailVariableError) {
      clearEmailTemplateFlag();
      return {
        openSnackBar: true,
        response: "Something went wrong, please try again!",
        snackBarVariant: "error",
      };
    }

    return null;
  }

  createTemplate = () => {
    const { createEmailTemplate, client } = this.props;
    const { templateTitle, templateSubject, content } = this.state;
    const templateData = {
      name: templateTitle,
      subject: templateSubject,
      email_body: content,
    };
    createEmailTemplate(templateData, client);
  };

  updateTemplate = () => {
    const { updateEmailTemplate, client } = this.props;
    const { templateTitle, templateSubject, content, uuid } = this.state;
    const templateData = {
      uuid: uuid,
      name: templateTitle,
      subject: templateSubject,
      email_body: content,
    };
    updateEmailTemplate(templateData, client);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContentChange = (value) => {
    this.setState({ content: value });
  };

  handleVariableChange = (e) => {
    this.setState({ variable: e.target.value, display: true });
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      templateTitle,
      templateSubject,
      content,
      variableData,
      variable,
      display,
      openSnackBar,
      snackBarVariant,
      response,
      isEdit,
    } = this.state;
    const modifiedVariable = "##" + variable + "##";
    return (
      <>
        <Suspense fallback={<div />}>
          <Snackbar
            open={openSnackBar}
            handleClose={this.handleCloseSnackbar}
            variant={snackBarVariant}
            message={response}
          />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitle}>Email Template</h4>
                  </CardText>
                </CardHeader>
                <CardBody className={classes.subscriptionCard}>
                  <form>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Variable
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <TextField
                          select
                          fullWidth
                          name={variable}
                          value={variable}
                          autoComplete=""
                          margin="normal"
                          variant="outlined"
                          onChange={this.handleVariableChange}
                          label="Select Variable"
                        >
                          {variableData.map((val, index) => (
                            <MenuItem key={val + index} value={val}>
                              {val}
                            </MenuItem>
                          ))}
                        </TextField>
                      </GridItem>
                    </GridContainer>
                    {display ? (
                      <div>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                            <FormLabel className={classes.labelHorizontal}>
                              Variable
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                            <Input
                              id="adornment-password"
                              value={modifiedVariable}
                              endAdornment={
                                <InputAdornment position="end">
                                  <Tooltip title="Copy">
                                    <CopyToClipboard text={modifiedVariable}>
                                      <IconButton disableRipple>
                                        <FileCopyIcon />
                                      </IconButton>
                                    </CopyToClipboard>
                                  </Tooltip>
                                </InputAdornment>
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    ) : (
                      <> </>
                    )}
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Template Title
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <TextField
                          fullWidth
                          id="filled-name-input"
                          type="text"
                          name="templateTitle"
                          autoComplete=""
                          margin="normal"
                          variant="outlined"
                          label="Please Enter Template Title"
                          value={templateTitle}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Template Subject
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <TextField
                          fullWidth
                          id="filled-name-input"
                          type="text"
                          name="templateSubject"
                          autoComplete=""
                          margin="normal"
                          variant="outlined"
                          label="Please Enter Template Subject "
                          value={templateSubject}
                          onChange={(e) => this.handleChange(e)}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Email Content
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                        <ReactQuill
                          style={{
                            minHeight: 500,
                            marginTop: 30,
                            display: "block",
                            height: "80%",
                          }}
                          value={content}
                          onChange={this.handleContentChange}
                          theme={"snow"}
                        />
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardBody>
                <CardFooter style={{ justifyContent: "flex-end" }}>
                  {isEdit ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="info"
                      style={{ fontSize: "0.95rem", marginTop: 30 }}
                      onClick={this.updateTemplate}
                    >
                      UPDATE
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="info"
                      style={{ fontSize: "0.95rem", marginTop: 30 }}
                      onClick={this.createTemplate}
                    >
                      Save
                    </Button>
                  )}
                  {/* <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  color="info"
                  style={{ fontSize: "0.95rem", marginLeft: 20, marginTop: 30 }}
                >
                  SEND TEST MAIL
                </Button> */}
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </Suspense>
      </>
    );
  }
}
ManageEmailTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    emailTemplateData:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.emailTemplateData,
    getEmailTemplateSuccess:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.getEmailTemplateSuccess,
    updateEmailTemplateSuccess:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.updateEmailTemplateSuccess,
    updateEmailTemplateError:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.updateEmailTemplateError,
    createEmailTemplateSuccess:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.createEmailTemplateSuccess,
    createEmailTemplateError:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.createEmailTemplateSuccess,
    emailVariableData:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.emailVariableData.emailVariables,
    getEmailVariableSuccess:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.getEmailVariableSuccess,
    getEmailVariableError:
      state.emailTemplateReducer &&
      state.emailTemplateReducer.getEmailVariableError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEmailTemplate,
      clearEmailTemplateFlag,
      createEmailTemplate,
      updateEmailTemplate,
      getEmailVariable,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(ManageEmailTemplate)));
