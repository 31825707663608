import {
  GET_ALL_EMAIL_CONFIGURATION_REQUEST,
  GET_ALL_EMAIL_CONFIGURATION_SUCCESS,
  GET_ALL_EMAIL_CONFIGURATION_ERROR,
  GET_EMAIL_CONFIGURATION_SUCCESS,
  GET_EMAIL_CONFIGURATION_ERROR,
  CREATE_EMAIL_CONFIGURATION_REQUEST,
  CREATE_EMAIL_CONFIGURATION_SUCCESS,
  CREATE_EMAIL_CONFIGURATION_ERROR,
  UPDATE_EMAIL_CONFIGURATION_REQUEST,
  UPDATE_EMAIL_CONFIGURATION_SUCCESS,
  UPDATE_EMAIL_CONFIGURATION_ERROR,
  DELETE_EMAIL_CONFIGURATION_REQUEST,
  DELETE_EMAIL_CONFIGURATION_SUCCESS,
  DELETE_EMAIL_CONFIGURATION_ERROR,
  CLEAR_EMAIL_CONFIGURATION_FLAG,
  GET_EMAIL_CONFIGURATION_REQUEST
} from "../type/EmailConfigurationType";

const initialState = {
  allEmailConfigurationData: [],
  getAllEmailConfigurationSuccess: false,
  getAllEmailConfigurationError: false,

  emailConfigurationData: [],
  getEmailConfigurationSuccess: false,
  getEmailConfigurationError: false,

  createEmailConfigurationSuccess: false,
  createEmailConfigurationError: false,

  updateEmailConfigurationSuccess: false,
  updateEmailConfigurationError: false,

  deleteEmailConfigurationSuccess: false,
  deleteEmailConfigurationError: false
};

export default function emailConfigurationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_EMAIL_CONFIGURATION_REQUEST:
      return {
        ...state,
        getAllEmailConfigurationSuccess: false,
        getAllEmailConfigurationError: false
      };
    case GET_ALL_EMAIL_CONFIGURATION_SUCCESS:
      return {
        ...state,
        getAllEmailConfigurationSuccess: true,
        getAllEmailConfigurationError: false,
        allEmailConfigurationData: action.payload.data.emailConfigurations
      };
    case GET_ALL_EMAIL_CONFIGURATION_ERROR:
      return {
        ...state,
        getAllEmailConfigurationSuccess: false,
        getAllEmailConfigurationError: true
      };
    case GET_EMAIL_CONFIGURATION_REQUEST:
      return {
        ...state,
        getEmailConfigurationSuccess: false,
        getEmailConfigurationError: false
      };
    case GET_EMAIL_CONFIGURATION_SUCCESS:
      return {
        ...state,
        getEmailConfigurationSuccess: true,
        getEmailConfigurationError: false,
        emailConfigurationData: action.payload.data.emailConfiguration,
      };
    case GET_EMAIL_CONFIGURATION_ERROR:
      return {
        ...state,
        getEmailConfigurationSuccess: false,
        getEmailConfigurationError: true
      };
    case CREATE_EMAIL_CONFIGURATION_REQUEST:
      return {
        ...state,
        createEmailConfigurationSuccess: false,
        createEmailConfigurationError: false
      };
    case CREATE_EMAIL_CONFIGURATION_SUCCESS:
      return {
        ...state,
        createEmailConfigurationSuccess: true,
        createEmailConfigurationError: false
      };
    case CREATE_EMAIL_CONFIGURATION_ERROR:
      return {
        ...state,
        createEmailConfigurationSuccess: false,
        createEmailConfigurationError: true
      };
    case UPDATE_EMAIL_CONFIGURATION_REQUEST:
      return {
        ...state,
        updateEmailConfigurationSuccess: false,
        updateEmailConfigurationError: false
      };
    case UPDATE_EMAIL_CONFIGURATION_SUCCESS:
      return {
        ...state,
        updateEmailConfigurationSuccess: true,
        updateEmailConfigurationError: false,
        allEmailConfigurationData: action.payload.data.emailConfigurations
      };
    case UPDATE_EMAIL_CONFIGURATION_ERROR:
      return {
        ...state,
        updateEmailConfigurationSuccess: false,
        updateEmailConfigurationError: true
      };
    case DELETE_EMAIL_CONFIGURATION_REQUEST:
      return {
        ...state,
        deleteEmailConfigurationSuccess: false,
        deleteEmailConfigurationError: false
      };
    case DELETE_EMAIL_CONFIGURATION_SUCCESS:
      return {
        ...state,
        deleteEmailConfigurationSuccess: true,
        deleteEmailConfigurationError: false,
        allEmailConfigurationData: state.allEmailConfigurationData.filter(item => item.uuid !== action.payload.data.DeleteEmailConfiguration.uuid)
      };
    case DELETE_EMAIL_CONFIGURATION_ERROR:
      return {
        ...state,
        deleteEmailConfigurationSuccess: false,
        deleteEmailConfigurationError: true
      };
    case CLEAR_EMAIL_CONFIGURATION_FLAG:
      return {
        ...state,
        getAllEmailConfigurationSuccess: false,
        getAllEmailConfigurationError: false,
        getEmailConfigurationSuccess: false,
        getEmailConfigurationError: false,
        createEmailConfigurationSuccess: false,
        createEmailConfigurationError: false,
        updateEmailConfigurationSuccess: false,
        updateEmailConfigurationError: false,
        deleteEmailConfigurationSuccess: false,
        deleteEmailConfigurationError: false
      };
    default:
      return {
        ...state
      };
  }
}
