import {
  GET_PATIENT_REQUEST,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_ERROR,
  CLEAR_PATIENT_FLAGS
} from "../type/PatientType";

const initialState = {
  getPatientSuccess: false,
  patientData: "",
  getPatientError: false
};

export default function patientReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PATIENT_REQUEST:
      return {
        ...state,
        getPatientSuccess: false,
        getPatientError: false
      };
    case GET_PATIENT_SUCCESS:
      return {
        ...state,
        getPatientSuccess: true,
        getPatientError: false,
        patientData: action.payload.data.users
      };
    case GET_PATIENT_ERROR:
      return {
        ...state,
        getPatientSuccess: false,
        getPatientError: true
      };
    case CLEAR_PATIENT_FLAGS:
      return {
        getPatientSuccess: false,
        getPatientError: false
      };
    default:
      return {
        ...state
      };
  }
}
