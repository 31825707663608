// Get All Email Templates
export const GET_ALL_EMAIL_TEMPLATES_REQUEST = "GET_ALL_EMAIL_TEMPLATES_REQUEST";
export const GET_ALL_EMAIL_TEMPLATES_SUCCESS = "GET_ALL_EMAIL_TEMPLATES_SUCCESS";
export const GET_ALL_EMAIL_TEMPLATES_ERROR = "GET_ALL_EMAIL_TEMPLATES_ERROR";

// Get Email Template
export const GET_EMAIL_TEMPLATE_REQUEST = "GET_EMAIL_TEMPLATE_REQUEST";
export const GET_EMAIL_TEMPLATE_SUCCESS = "GET_EMAIL_TEMPLATE_SUCCESS";
export const GET_EMAIL_TEMPLATE_ERROR = "GET_EMAIL_TEMPLATE_ERROR";

//Create Email Template
export const CREATE_EMAIL_TEMPLATE_REQUEST = "CREATE_EMAIL_TEMPLATE_REQUEST";
export const CREATE_EMAIL_TEMPLATE_SUCCESS = "CREATE_EMAIL_TEMPLATE_SUCCESS";
export const CREATE_EMAIL_TEMPLATE_ERROR = "CREATE_EMAIL_TEMPLATE_ERROR";

//Update Email Template
export const UPDATE_EMAIL_TEMPLATE_REQUEST = "UPDATE_EMAIL_TEMPLATE_REQUEST";
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = "UPDATE_EMAIL_TEMPLATE_SUCCESS";
export const UPDATE_EMAIL_TEMPLATE_ERROR = "UPDATE_EMAIL_TEMPLATE_ERROR";

//Delete Email Template
export const DELETE_EMAIL_TEMPLATE_REQUEST = "DELETE_EMAIL_TEMPLATE_REQUEST";
export const DELETE_EMAIL_TEMPLATE_SUCCESS = "DELETE_EMAIL_TEMPLATE_SUCCESS";
export const DELETE_EMAIL_TEMPLATE_ERROR = "DELETE_EMAIL_TEMPLATE_ERROR";

// Get Variable Template
export const GET_EMAIL_VARIABLE_REQUEST = "GET_EMAIL_VARIABLE_REQUEST";
export const GET_EMAIL_VARIABLE_SUCCESS = "GET_EMAIL_VARIABLE_SUCCESS";
export const GET_EMAIL_VARIABLE_ERROR = "GET_EMAIL_VARIABLE_ERROR";

//Clear Flag 
export const CLEAR_EMAIL_TEMPLATE_FLAG = "CLEAR_EMAIL_TEMPLATE_FLAG";