import React, { Component } from "react";
// import { Button } from "@progress/kendo-react-buttons";
// import { Input } from "@progress/kendo-react-inputs";
import { TextField, withStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import PropTypes from "prop-types";
import FormLabel from "@material-ui/core/FormLabel";
import ChatIcon from "@material-ui/icons/Chat";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";


class ChatLogin extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <Card>
            <form onSubmit={this.props.handleLogin} autoComplete="off">
              <CardHeader color="info" text>
                <CardIcon color="info">
                  <ChatIcon />
                </CardIcon>
              </CardHeader>
              <CardBody className={classes.subscriptionCard}>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      User Name
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <TextField
                      variant="outlined"
                      name="username"
                      label="Username"
                      required={true}
                      style={{ width: "100%" }}
                      value={this.props.username}
                      onChange={this.props.handleUsernameChange}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="sm"
                  color="info"
                  style={{ fontSize: "0.95rem", marginTop: 30 }}                  
                >
                  Log In
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>      
    );
  }
}
ChatLogin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChatLogin);
