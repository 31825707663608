/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import ReactTable from "react-table";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { Tooltip, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const CardIcon = lazy(() => import("components/Card/CardIcon.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const Snackbar = lazy(() => import("components/Snackbar/SnackbarContent"));
const DeleteDialog = lazy(() => import("components/DeleteDialog/DeleteDialog"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));

import {
  getAllQuestions,
  clearQuestionsFlag,
  deleteQuestion,
} from "../../redux/actions/QuestionActions";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

class QuestionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSnackBar: false,
      response: "",
      snackBarVariant: "success",
      uuid: "",
      openDialog: false,
    };
  }

  componentDidMount() {
    const { getAllQuestions, client } = this.props;
    getAllQuestions(client);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      clearQuestionsFlag,
      deleteQuestionSuccess,
      deleteQuestionError,
    } = props;

    if (deleteQuestionSuccess) {
      clearQuestionsFlag();
      return {
        openSnackBar: true,
        response: "Question Page Deleted SuccessFully",
        snackBarVariant: "success",
      };
    }

    if (deleteQuestionError) {
      clearQuestionsFlag();
      return {
        openSnackBar: true,
        response: "Something went wrong, try again!",
        snackBarVariant: "error",
      };
    }

    return null;
  }

  createQuestion = () => {
    this.props.history.push("/admin/manage-question");
  };

  openDeleteDialog = (uuid) => {
    this.setState({
      openDialog: true,
      uuid: uuid,
    });
  };

  deleteQuestionFromList = (uuid) => {
    const { deleteQuestion, client } = this.props;
    deleteQuestion({ uuid }, client);
  };

  updateQuestion = (uuid) => {
    this.props.history.push(`/admin/manage-question?id=${uuid}`, {
      uuid: uuid,
      isEdit: true,
    });
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  render() {
    const columns = [
      {
        id: "id",
        Header: "No.",
        width: 130,
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14,
        },
        Cell: (row) => (
          <div style={{ textAlign: "center", fontSize: 16 }}>
            {row.index + 1}
          </div>
        ),
      },
      {
        id: "question",
        Header: "Question",
        accessor: "question",
        filterable: false,
        Cell: (row) => (
          <div style={{ whiteSpace: "unset", cursor: "pointer", fontSize: 16 }}>
            {row.value}
          </div>
        ),
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14,
        },
      },
      {
        Header: "Action",
        filterable: false,
        sortable: false,
        width: 100,
        headerStyle: {
          fontWeight: "bold",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "unset",
          fontSize: 14,
        },
        Cell: (row) => (
          <div
            style={{
              whiteSpace: "unset",
              cursor: "pointer",
              textDecoration: "underline",
              color: "#3f51b5",
              display: "flex",
            }}
          >
            <Tooltip title="Edit">
              <IconButton
                onClick={() => this.updateQuestion(row.original.uuid)}
                disableRipple
              >
                <EditIcon className={classes.iconEdit} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove">
              <IconButton
                onClick={() => this.openDeleteDialog(row.original.uuid)}
                disableRipple
              >
                <DeleteIcon className={classes.iconRemove} />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ];

    const { classes } = this.props;
    const {
      openSnackBar,
      response,
      snackBarVariant,
      uuid,
      openDialog,
    } = this.state;
    return (
      <>
        <Suspense fallback={<div />}>          
          <Snackbar
            open={openSnackBar}
            handleClose={this.handleCloseSnackbar}
            variant={snackBarVariant}
            message={response}
          />
          <DeleteDialog
            deleteIem={this.deleteQuestionFromList}
            uuid={uuid}
            open={openDialog}
            handleClose={this.handleCloseDialog}
          />

          <div style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="info"
              onClick={this.createQuestion}
            >
              Add Questions
            </Button>
          </div>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <QuestionAnswerIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Questions</h4>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    className="-highlight"
                    noDataText="No candidates matching the provided criteria"
                    columns={columns}
                    data={this.props.questions}
                    defaultPageSize={15}
                    defaultFilterMethod={this.filterCaseInsensitive}
                  ></ReactTable>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Suspense>
      </>
    );
  }
}
QuestionsList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    getAllQuestionsSuccess:
      state.questionReducer && state.questionReducer.getAllQuestionsSuccess,
    questions: state.questionReducer && state.questionReducer.questionsList,
    deleteQuestionSuccess:
      state.questionReducer && state.questionReducer.deleteQuestionSuccess,
    deleteQuestionError:
      state.questionReducer && state.questionReducer.deleteQuestionError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllQuestions,
      deleteQuestion,
      clearQuestionsFlag,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(QuestionsList)));
