/* eslint-disable */
import React, { Component, lazy, Suspense } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";
import Apps from "@material-ui/icons/Apps";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Table = lazy(() => import("components/Table/Table.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const CardIcon = lazy(() => import("components/Card/CardIcon.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const simpleButtons = [
  { color: "success", icon: Edit, tooltip: "Edit" },
  { color: "danger", icon: Close, tooltip: "Remove" },
].map((prop, key) => {
  return (
    <Tooltip title={prop.tooltip} placement="bottom">
      <Button
        color={prop.color}
        simple
        key={key}
        style={{
          margin: "0 0 0 5px",
          padding: "5px",
        }}
        href={prop.url}
      >
        <prop.icon
          style={{
            verticalAlign: "middle",
            width: "17px",
            height: "17px",
            top: "-1px",
            position: "relative",
          }}
        />
      </Button>
    </Tooltip>
  );
});

class SubscriptionDetails extends Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <Suspense fallback={<div />}>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <Apps />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    Subscription Details
                  </h4>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHead={[
                      "#",
                      "Subscription Type",
                      "Subscription Name",
                      "Subscription Price",
                      "Discount",
                      "Price After Discount",
                      "Action",
                    ]}
                    tableData={[
                      [
                        "1",
                        "Monthly",
                        "Basic",
                        "€ 120",
                        "5%",
                        "€ 114",
                        simpleButtons,
                      ],
                      [
                        "2",
                        "Monthly",
                        "Premium",
                        "€ 145",
                        "0%",
                        "€ 145",
                        simpleButtons,
                      ],
                      [
                        "3",
                        "Monthly",
                        "Premium Plus",
                        "€ 200",
                        "0%",
                        "€ 200",
                        simpleButtons,
                      ],
                      [
                        "4",
                        "Quarterly",
                        "Basic",
                        "€ 340",
                        "0%",
                        "€ 340",
                        simpleButtons,
                      ],
                      [
                        "5",
                        "Quarterly",
                        "Premium",
                        "€ 413",
                        "0%",
                        "€ 413",
                        simpleButtons,
                      ],
                      [
                        "6",
                        "Quarterly",
                        "Premium Plus",
                        "€ 570",
                        "0%",
                        "€ 570",
                        simpleButtons,
                      ],
                      [
                        "7",
                        "Yearly",
                        "Basic",
                        "€ 1296",
                        "0%",
                        "€ 1296",
                        simpleButtons,
                      ],
                      [
                        "8",
                        "Yearly",
                        "Premium",
                        "€ 1560",
                        "0%",
                        "€ 1560",
                        simpleButtons,
                      ],
                      [
                        "9",
                        "Yearly",
                        "Premium Plus",
                        "€ 2160",
                        "0%",
                        "€ 2160",
                        simpleButtons,
                      ],
                    ]}
                    customCellClasses={[
                      classes.center,
                      classes.right,
                      // classes.right
                    ]}
                    customClassesForCells={[0, 6]}
                    customHeadCellClasses={[
                      classes.center,
                      classes.right,
                      // classes.right
                    ]}
                    customHeadClassesForCells={[0, 6]}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Suspense>
      </>
    );
  }
}
SubscriptionDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(SubscriptionDetails);
